export const environment = {
  production: false,
  // serverUrl: 'http://172.16.88.152:8101/',
  // serverUrl: 'http://110.10.129.15:9402/',
  serverUrl: 'https://back.이사알바.com/',

  impCode: 'imp25325740',
  pg: 'iamporttest_3',
  // pg: 'tosspayments.store-97ab5c92-7ac2-478b-8ffc-34135579282b',
  androidHeaderColor: '#2154bd',
  //isTest: true,
  screenWidth: 1160,
};

export const ApiUrl = {
  PromoteMember: environment.serverUrl + "api/promoteMember",
  GetHistory: environment.serverUrl + "api/getHistory",
  paymentMobile: environment.serverUrl + "api/mobilePaymentComplete",
  payment: environment.serverUrl + "api/paymentComplete",
  SaveOrders: environment.serverUrl + "api/saveOrders",
  SaveBilingOrders: environment.serverUrl + "api/savebilingOrders",
  // Common
  Login: environment.serverUrl + "auth/signIn",
  SignUp: environment.serverUrl + "auth/signUp",
  FindPassword: environment.serverUrl + "auth/findPassword",
  CheckDup: environment.serverUrl + "auth/checkDup",
  SetSocketId: environment.serverUrl + "api/setSocketId",
  UploadFile: environment.serverUrl + "auth/uploadFile",

  DeleteRequest: environment.serverUrl + "api/deleteRequest",
  DeleteAccepted: environment.serverUrl + "api/deleteAccepted",
  GetPolicy: environment.serverUrl + "auth/getPolicy",
  GetSetting: environment.serverUrl + "api/getSettings",
  UpdateInfo: environment.serverUrl + "api/updateInfo",
  GetJobOffers: environment.serverUrl + "api/getJobOffer",
  GetJobHunttingOfferInfo: environment.serverUrl + "api/GetJobHunttingOfferInfo",
  AcceptJobOffer: environment.serverUrl + "api/accepOffer",
  EndJobOffer: environment.serverUrl + "api/endOffer",
  RegisterJobHunting: environment.serverUrl + "api/registerJobHunting",
  GetJobHunttings: environment.serverUrl + "api/getJobHunting",
  RegisterJobOffer: environment.serverUrl + "api/registerJobOffer",
  UpdateJobOffer: environment.serverUrl + "api/updateJobOffer",
  RemoveJobOffer: environment.serverUrl + "api/RemoveJobOffer",
  AcceptJobHuntting: environment.serverUrl + "api/accepHuntting",

  GetNotifications: environment.serverUrl + "api/getNotification",
  GetQuestions: environment.serverUrl + "api/getQuestion",
  InsertQuestions: environment.serverUrl + "api/insertQuestion",

  GetEmployeeReservedHuntting: environment.serverUrl + "api/getEmployeeReservedHutting",
  GetEmployeeAcceptedHuntting: environment.serverUrl + "api/getEmployeeAcceptedHutting",
  GetEmployeeCompensation: environment.serverUrl + "api/getEmployeeCompensation",

  GetEmployerReservedHuntting: environment.serverUrl + "api/getEmployerReservedHutting",
  GetEmployerAcceptedHuntting: environment.serverUrl + "api/getEmployerAcceptedHutting",
  AllowEmployerHuntting: environment.serverUrl + "api/allowEmployerHuntting",
  UpdateContactDetail: environment.serverUrl + "api/updateContactDetail",

  CancelContact: environment.serverUrl + "api/cancelContact",
  DeleteCanceledContact: environment.serverUrl + "api/deleteCanceledContact",
  EndContact: environment.serverUrl + "api/endContact",

  GetCustomer: environment.serverUrl + "api/getCustomer",
  InsertCustomer: environment.serverUrl + "api/insertCustomer",
  UpdateCustomer: environment.serverUrl + "api/updateCustomer",
  DeleteCustomer: environment.serverUrl + "api/deleteCustomer",

  DeleteAcceptedHunting: environment.serverUrl + "api/deleteAcceptedHunting",
  DeleteReservedHuntting: environment.serverUrl + "api/deleteReservedHunting",

  // Alarm
  GetAlarmList: environment.serverUrl + "api/getAlarmList",
  SetAlarmState: environment.serverUrl + "api/setAlarmState",
  DeleteAlarm: environment.serverUrl + "api/deleteAlarm",

  // Setting
  GetMyList: environment.serverUrl + "api/getMyList",
  SetName: environment.serverUrl + "api/setName",
  GetProfile: environment.serverUrl + "auth/getProfile",
  // Community
  GetKind: environment.serverUrl + "api/community/getKind",
  GetKindList: environment.serverUrl + "api/community/getKindList",
  GetCategoryList: environment.serverUrl + "api/community/getCategoryList",
  GetMainList: environment.serverUrl + "api/community/getMainList",
  Edit: environment.serverUrl + "api/community/edit",
  Create: environment.serverUrl + "api/community/create",
  DeclareCommunity: environment.serverUrl + "api/community/declareCommunity",
  SearchCommunity: environment.serverUrl + "api/community/searchCommunity",
  GetInfo: environment.serverUrl + "api/community/getInfo",
  CreateFeedback: environment.serverUrl + "api/community/createFeedback",
  EditFeedback: environment.serverUrl + "api/community/editFeedback",
  LikeCommunity: environment.serverUrl + "api/community/likeCommunity",
  DisLikeCommunity: environment.serverUrl + "api/community/disLikeCommunity",
  LikeFeedback: environment.serverUrl + "api/community/likeFeedback",
  DisLikeFeedback: environment.serverUrl + "api/community/disLikeFeedback",
  DeleteCommunity: environment.serverUrl + "api/community/deleteCommunity",
  DeleteFeedback: environment.serverUrl + "api/community/deleteFeedback",
  GetFeedbackInfo: environment.serverUrl + "api/community/getFeedbackInfo",
  GetNearId: environment.serverUrl + "api/community/getNearId",

  GetBanners: environment.serverUrl + "api/banners"
};

export const ApiConfig = {
  REFUSE_CODE: -1,
  FAILD_CODE: 0,
  SUCCESS_CODE: 1,
  EXIST_CODE: 2,
  DIAMOND_CODE: 3,
}

export const SettingType = {
  CareerLevel: "career_levels",
  VehicleType: "vehicle_types",
  WorkType: "work_types",
  EmployType: "employment_types"
}