import { Injectable } from '@angular/core';
import { LocalStorage } from './local-storage';

@Injectable({
  providedIn: 'root'
})
export class UserInfo {
  public id = 0;
  public role = "";
  public name = "";
  public age = "";
  public loginid = "";
  public token = "";
  public photo = "";
  public contact_address = "";
  public address = "";
  public point: number;
  public socket_id = "";
  public isRegular = 0;
  public data: any;
  public member_register_date = "";
  public member_expire_date = "";
  public can_push = "Y";
  public email = "";

  private settingData: {};

  constructor(private storage: LocalStorage) {
  }

  set(info) {
    this.id = info.id;
    this.role = info.role;
    this.name = info.name;
    this.age = info.age;
    this.loginid = info.userId;
    this.photo = info.photo;
    this.contact_address = info.contact_address;
    this.address = info.address;
    this.point = info.money;
    this.data = info;
    this.isRegular = info.is_regular_member;
    this.member_expire_date = info.member_expire_date;
    this.member_register_date = info.member_register_date;
    this.email = info.email || 'testName@gmail.com';
  }
  setData(data) {
    this.data = data;
  }
  setToken(token) {
    this.token = token;
  }

  async save() {
    let info = {
      id: this.id,
      name: this.name,
      age: this.age,
      role: this.role,
      loginid: this.loginid,
      token: this.token,
      photo: this.photo,
      point: this.point,
      contact_address: this.contact_address,
      address: this.address,
      socket_id: this.socket_id,
      data: this.data,
      isRegular: this.data.is_regular_member,
      member_expire_date: this.member_expire_date,
      member_register_date: this.member_register_date,
      email: this.email,
    };
    await this.storage.setUserInfo(info);
  }

  async get() {
    let info = await this.storage.getUserInfo();
    if (info) {
      this.id = info.id;
      this.loginid = info.loginid;
      this.name = info.name;
      this.role = info.role;
      this.token = info.token;
      this.photo = info.photo;
      this.point = info.point;
      this.contact_address = info.contact_address;
      this.address = info.address;
      this.socket_id = info.socket_id;
      this.data = info.data;
      this.isRegular = info.isRegular;
      this.member_expire_date = info.member_expire_date;
      this.member_register_date = info.member_register_date;
      this.email = info.email;
    }
    return info;
  }

  async saveSetting(setting) {
    await this.storage.setSetting(setting);
  }
  async getSetting() {
    if (this.settingData == null) {
      let setting = await this.storage.getSetting();
      this.settingData = setting;
    }
    return this.settingData;
  }

  async getSettingLevel(key, level) {
    var levelString = "";
    if (level == "male" || level == "작업부") {
      levelString = "작업부";
      return levelString;
    } else if (level == "female" || level == "주방") {
      levelString = "주방";
      return levelString;
    }
    let settingData = await this.getSetting();
    let careerData = settingData["result"][key];
    for (var i = 0; i < careerData.length; i++) {
      if (careerData[i].id == level) {
        levelString = careerData[i].name;
        break;
      }
    }
    return levelString;
  }

  async remove() {
    await this.storage.removeUserInfo();
  }
}
