import { Component, NgZone } from '@angular/core';
import { Platform, AlertController, ToastController, LoadingController, ModalController, Config, NavController } from '@ionic/angular';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Device } from '@ionic-native/device/ngx';
import { TranslateService } from '@ngx-translate/core';
import { environment, ApiUrl } from '../environments/environment';
import { LocalStorage } from './services/local-storage';
import { Router, NavigationEnd } from '@angular/router';
import Utils from './utils';
import { UserInfo } from './services/user-info';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmitEvent, EventBusService } from './services/event-bus.service';
import { FormControl } from '@angular/forms';
import { FCM } from '@ionic-native/fcm/ngx';

declare let io: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  private loader: any;
  private currentUrl: string;
  private socket = null;

  constructor(private platform: Platform,
    private router: Router,
    private storage: LocalStorage,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private headerColor: HeaderColor,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userInfo: UserInfo,
    private http: HttpClient,
    private fcm: FCM,
    private navCtrl: NavController,
    private eventBusService: EventBusService,
    private translate: TranslateService) {

    this.initializeApp();
  }

  async initializeApp() {
    if (this.platform.is('desktop')) {
      const config = new Config;
      config.set('rippleEffect', false);
      config.set('animated', false);
    }

    this.subscribeToRouterChanges();
    this.setupEvents();
    if (this.platform.is('cordova')) {
      await this.platform.ready();
      this.splashScreen.hide();
      this.setupPush();
      this.setupAndroidHeaderColor();
      this.setupStatusBar();
      this.backButtonPressedEvent();
    }


    await this.startPage();
  }
  backButtonPressedEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      const url = this.router.url;

      if (url == "/" || url == "/main") {
        this.alertCtrl
          .create({
            header: "24알바",
            message: "종료하시겠습니까?",
            backdropDismiss: false,
            buttons: [
              {
                text: "아니요",
                role: "cancel",
              },
              {
                text: "네",
                handler: () => {
                  navigator["app"].exitApp();
                }
              }
            ]
          })
          .then(alert => {
            alert.present();
          });
      } else if (url == "/app/search" || url == "/app/alarm" || url == "/app/my") {
        this.router.navigate(['/main']);
      } else{
        this.navCtrl.back();
      }
    });
  }

  async startPage() {
    let info = await this.userInfo.get();
    if (info) {
      this.goTo('/job-offer');
      this.eventBusService.emit(new EmitEvent("logined"));
    }
  }

  subscribeToRouterChanges() {

    this.router.events.subscribe(async (event) => {

      if (event instanceof NavigationEnd) {

        if (this.router.url === '/' && !this.currentUrl) {

        }

        this.currentUrl = this.router.url;
      }
    })

  }

  goTo(page: string) {
    if (page != "/") {
      if (window.innerWidth > environment.screenWidth) {
        page = "/pc/" + page;
        this.router.navigate([page], {});
      } else {
        page = "/main/" + page;
        this.router.navigate([page], {});
      }
    } else {
      this.router.navigate([page], {});
    }
  }

  setupStatusBar() {
    if (this.platform.is('ios')) {
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleDefault();
    } else {
      this.statusBar.backgroundColorByHexString(environment.androidHeaderColor);
    }
  }

  setupAndroidHeaderColor() {
    if (environment.androidHeaderColor && this.platform.is('android')) {
      this.headerColor.tint(environment.androidHeaderColor);
    }
  }

  setupEvents() {
    var self = this;
    this.eventBusService.on('user:logout', () => {
      this.storage.removeUserInfo();
      if (this.socket) {
        this.socket.disconnect(true);
      }
      this.goTo('/');
    });
    this.eventBusService.on("logined", () => {
      console.log("login success!")
      self.setupSocket();
    });
  }

  setupPush() {
    this.fcm.getToken().then(token => {
      // Save Token

    });
    this.fcm.onNotification().subscribe(data => {
      if (data.wasTapped) {
        console.log("Received in background");
      } else {
        console.log("Received in foreground");
      };
    });
    this.fcm.onTokenRefresh().subscribe(token => {
      // Save Token

    });
  }

  setupSocket() {
    if (this.socket) {
      this.socket.disconnect(true);
    }
    this.socket = io.connect(environment.serverUrl);
    this.socket.on("connect", async () => {
      console.log("Client Socket Connected - Id : ", this.socket.id);
      let info = await this.userInfo.get();
      if (info) {
        this.userInfo.socket_id = this.socket.id;
        await this.userInfo.save();
        console.log("User Socket Id :" + this.userInfo.socket_id);
        var httpHeader = new HttpHeaders({
          'Authorization': this.userInfo.token,
        })
        var params = {
          socket_id: this.userInfo.socket_id
        }
        let result = await this.http.post(ApiUrl.SetSocketId, params, { headers: httpHeader }).toPromise();
        console.log(result);
      }
    });

    this.socket.on("changeAlam", (data) => {
      this.eventBusService.emit(new EmitEvent("changeAlarm", {}));
    });
  }

  async showAlert(title: string = '', message: string = '', okText: string = 'OK') {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [okText],
    });
    return await alert.present();
  }

  async showToast(message: string = '', buttons: any = null, duration: any = 3000) {

    const closeText = await this.translate.get('CLOSE').toPromise();

    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      color: 'primary',
      position: 'bottom',
      cssClass: 'tabs-bottom',
      buttons: buttons || [{
        text: closeText,
        role: 'cancel',
      }]
    });

    return await toast.present();
  }

  async showLoadingView() {

    const loadingText = await this.translate.get('LOADING').toPromise();

    this.loader = await this.loadingCtrl.create({
      message: loadingText
    });

    return await this.loader.present();
  }

  async dismissLoadingView() {

    if (!this.loader) return;

    try {
      await this.loader.dismiss()
    } catch (error) {
      console.log('ERROR: LoadingController dismiss', error);
    }
  }

}
