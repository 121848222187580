import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage {

  constructor(private storage: Storage) {
  }

  getIsPushEnabled(): Promise<boolean> {
    return this.storage.get('isPushEnabled');
  }

  setIsPushEnabled(val: boolean) {
    return this.storage.set('isPushEnabled', val);
  }


  getUserInfo(): Promise<any> {
    return this.storage.get('user_info');
  }

  setUserInfo(val) {
    return this.storage.set('user_info', val);
  }

  getSetting(): Promise<any> {
    return this.storage.get('setting');
  }

  setSetting(val) {
    return this.storage.set('setting', val);
  }
  
  removeUserInfo(): Promise<any> {
    return this.storage.remove('user_info');
  }
}
