import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SignInPage } from './pages/mobile/sign-in/sign-in';

const routes: Routes = [
  { path: 'main', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'pc', loadChildren: () => import('./pc/pc.module').then(m => m.PcPageModule) },
  { path: '', loadChildren: () => import('./pages/mobile/sign-in/sign-in.module').then(m => m.SignInPageModule) },
  { path: 'main/sign-up', loadChildren: () => import('./pages/mobile/sign-up/sign-up.module').then(m => m.SignUpPageModule) },
  { path: 'main/sign-up-enterprise', loadChildren: () => import('./pages/mobile/sign-up-enterprise/sign-up-enterprise.module').then(m => m.SignUpPageModule) },
  { path: 'main/selection-register', loadChildren: () => import('./pages/mobile/selection-register/selection-register.module').then(m => m.SelectionRegisterPageModule) },
  { path: 'main/search-offer-setting', loadChildren: () => import('./pages/mobile/search-offer-setting/search-offer-setting.module').then(m => m.SearchOfferSettingModule) },
  { path: 'main/search-hunting-setting', loadChildren: () => import('./pages/mobile/search-hunting-setting/search-hunting-setting.module').then(m => m.SearchHuntingSettingModule) },
  { path: 'pc/selection-register', loadChildren: () => import('./pages/pc/selection-register/selection-register.module').then(m => m.SelectionRegisterPageModule) },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/pc/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
];
//selection-register
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'corrected'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
